import React from 'react';

class About extends React.Component {
    render() {
        return (
            <div>
                <h2>about page content</h2>
            </div>
        )
    }
}



export default About;